<template>
  <CustomDrawer
    ref="CustomDrawer"
    :title="title"
    :show-footer="formStatus !== 'detail'"
    @openedCallback="openedCallback"
    @handleConfirm="handleConfirm"
  >
    <CustomForm
      ref="CustomForm"
      :form-model="formModel"
      :form-items="formItem"
      :form-rules="formRules"
    >
      <UploadImg slot="photoId" slot-scope="{item}" :upload-lists="photoUploadLists" :pic-disable="formStatus === 'detail'" @uploadChange="item.uploadChange" />
      <CustomMap slot="customMap" ref="CustomMap" :lat="formModel.latitude" :lng="formModel.longitude" :disabled="formStatus === 'detail'" @callback="formItem.customMap.callback" />
      <div slot="address">
        <el-input v-model="formModel.address" type="text" :maxlength="255" placeholder="请输入详细地址或在地图中选择" clearable :disabled="formStatus === 'detail'" />
        <div style="display: flex; margin-top: 10px;">
          <el-input v-model="formModel.latitude" type="text" placeholder="请在地图中选择位置以获取纬度" disabled style="margin-right: 5px;" />
          <el-input v-model="formModel.longitude" type="text" placeholder="请在地图中选择位置以获取经度" disabled style="margin-left: 5px;" />
        </div>
      </div>
      <RegionForm slot="tenant" ref="RegionForm" v-model="formModel.tenant" :multiple="true" :disabled="formStatus === 'detail'" />
    </CustomForm>
  </CustomDrawer>
</template>

<script>
import CustomDrawer from '@/components/CustomDrawer/index'
import CustomForm from '@/components/CustomForm/index'
import { mapActions } from 'vuex'
import RegionForm from '@/components/RegionForm'
import UploadImg from '@/components/UploadImg'
import CustomMap from '@/components/CustomMap/index'
export default {
  name: 'Form',
  components: { CustomMap, UploadImg, RegionForm, CustomDrawer, CustomForm },
  data() {
    return {
      title: '',
      formId: '',
      formStatus: '',
      photoUploadLists: [],
      formModel: {
        name: '',
        photoId: '',
        introduction: '',
        tenant: [],
        address: '',
        latitude: 0,
        longitude: 0,
        contactPerson: '',
        contactPhone: '',
        priceStart: '',
        priceEnd: ''
      }
    }
  },
  computed: {
    formItem() {
      return {
        'name': {
          'elColSpan': 24,
          'component': 'CustomFormText',
          'label': '名称',
          'maxlength': 255,
          'disabled': this.formStatus === 'detail'
        },
        'photoId': {
          'elColSpan': 24,
          'label': '图片',
          'slot': 'photoId',
          'uploadChange': data => {
            if (data.length) {
              this.$set(this.formModel, 'photoId', data[0])
            } else {
              this.$set(this.formModel, 'photoId', '')
            }
          }
        },
        'priceStart': {
          'elColSpan': 12,
          'component': 'CustomFormNumber',
          'label': '起始价格',
          'min': 0,
          'max': 99999999,
          'precision': 2,
          'step': 10,
          'controls': true,
          'disabled': this.formStatus === 'detail'
        },
        'priceEnd': {
          'elColSpan': 12,
          'component': 'CustomFormNumber',
          'label': '结束价格',
          'min': 0,
          'max': 99999999,
          'precision': 2,
          'step': 10,
          'controls': true,
          'disabled': this.formStatus === 'detail'
        },
        'contactPerson': {
          'elColSpan': 12,
          'component': 'CustomFormText',
          'label': '负责人',
          'maxlength': 100,
          'disabled': this.formStatus === 'detail'
        },
        'contactPhone': {
          'elColSpan': 12,
          'component': 'CustomFormText',
          'label': '联系方式',
          'maxlength': 100,
          'disabled': this.formStatus === 'detail'
        },
        'tenant': {
          'elColSpan': 24,
          'label': '服务区域',
          'slot': 'tenant'
        },
        'address': {
          'elColSpan': 24,
          'label': '地址信息',
          'slot': 'address'
        },
        'customMap': {
          'elColSpan': 24,
          'slot': 'customMap',
          'callback': data => {
            this.formModel.address = data.poiaddress
            this.formModel.latitude = data.latlng.lat
            this.formModel.longitude = data.latlng.lng
          }
        },
        'introduction': {
          'elColSpan': 24,
          'component': 'CustomFormTinymce',
          'label': '介绍',
          'disabled': this.formStatus === 'detail'
        }
      }
    },
    formRules() {
      return {
        name: [
          { required: true, message: '名称不能为空', trigger: ['blur', 'change'] }
        ],
        photoId: [
          { required: true, message: '图片不能为空', trigger: ['blur', 'change'] }
        ],
        contactPerson: [
          { required: true, message: '负责人不能为空', trigger: ['blur', 'change'] }
        ],
        contactPhone: [
          { required: true, message: '联系方式不能为空', trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (value && !this.$regex.telRegEx(value)) {
                callback(new Error('联系方式格式不正确'))
              } else {
                callback()
              }
            },
            trigger: ['blur', 'change']
          }
        ],
        priceStart: [
          { required: true, message: '起始价格不能为空', trigger: ['blur', 'change'] },
          { type: 'integer', max: this.formModel.priceEnd, message: '起始价格应小于等于结束价格', trigger: ['blur', 'change'] }
        ],
        priceEnd: [
          { required: true, message: '结束价格不能为空', trigger: ['blur', 'change'] },
          { type: 'integer', min: this.formModel.priceStart, message: '结束价格应大于等于起始价格', trigger: ['blur', 'change'] }
        ],
        tenant: [
          { required: true, message: '服务区域不能为空', trigger: ['blur', 'change'] }
        ],
        address: [
          { required: true, message: '地址信息不能为空', trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (!this.formModel.latitude || !this.formModel.longitude) {
                callback(new Error('坐标信息不能为空'))
              } else {
                callback()
              }
            },
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions(['nursingHomePublicDetail', 'nursingHomeAdd', 'nursingHomeUpdate']),
    handleAdd() {
      this.init('add')
    },
    handleEdit(id) {
      this.init('edit', id)
    },
    handleDetail(id) {
      this.init('detail', id)
    },
    init(formStatus, id = '') {
      Object.assign(this, this.$options.data.call(this))
      this.$refs.CustomDrawer.handleOpen()
      this.formId = id
      this.formStatus = formStatus
      switch (formStatus) {
        case 'add':
          this.title = '添加'
          break
        case 'edit':
          this.title = '编辑'
          break
        case 'detail':
          this.title = '查看'
          break
        default:
          this.title = '未知'
          break
      }
    },
    openedCallback() {
      this.initData().then(() => {
        this.$nextTick(() => {
          this.$refs.CustomMap.init()
          this.$refs.RegionForm.initRegion()
        })
        this.$refs.CustomForm.$refs.form.clearValidate()
        this.$refs.CustomDrawer.closeLoading()
      }).catch(() => {
        this.$refs.CustomDrawer.handleClose()
      })
    },
    initData() {
      return new Promise((resolve, reject) => {
        if (this.formStatus !== 'add') {
          this.nursingHomePublicDetail({ id: this.formId }).then(res => {
            if (res.code !== 200) {
              this.$message.error(res.msg)
              reject()
              return
            }

            this.photoUploadLists = [
              {
                'id': res.data.photoId,
                'url': res.data.photo
              }
            ]

            this.formModel.id = this.formId
            this.formModel.name = res.data.name
            this.formModel.photoId = res.data.photoId
            this.formModel.contactPerson = res.data.contactPerson
            this.formModel.contactPhone = res.data.contactPhone
            this.formModel.priceStart = res.data.priceStart
            this.formModel.priceEnd = res.data.priceEnd
            this.formModel.tenant = res.data.tenants.split('|').map((item) => {
              return item.split(',').slice(2)
            })
            this.formModel.address = res.data.address
            this.formModel.latitude = res.data.latitude
            this.formModel.longitude = res.data.longitude
            this.formModel.introduction = res.data.introduction
            resolve()
          }).catch(() => {
            reject()
          })
        } else {
          resolve()
        }
      })
    },
    handleConfirm() {
      this.$refs.CustomDrawer.openLoading()
      this.$refs.CustomForm.$refs.form.validate(res => {
        if (res) {
          const params = { ...this.formModel }
          params.tenant = params.tenant.map((item) => {
            return `1,101,${item.join(',')}`
          })

          switch (this.formStatus) {
            case 'add':
              this.nursingHomeAdd(params).then(res => {
                if (res.code !== 200) {
                  this.$refs.CustomDrawer.closeLoading()
                  this.$message.error(res.msg)
                  return
                }
                this.$message.success('添加成功')
                this.$emit('submit')
                this.$refs.CustomDrawer.handleClose()
              }).catch(() => {
                this.$refs.CustomDrawer.closeLoading()
              })
              break
            case 'edit':
              this.nursingHomeUpdate(params).then(res => {
                if (res.code !== 200) {
                  this.$refs.CustomDrawer.closeLoading()
                  this.$message.error(res.msg)
                  return
                }
                this.$message.success('修改成功')
                this.$emit('submit')
                this.$refs.CustomDrawer.handleClose()
              }).catch(() => {
                this.$refs.CustomDrawer.closeLoading()
              })
              break
            default:
              this.$refs.CustomDrawer.handleClose()
              break
          }
        } else {
          this.$refs.CustomDrawer.closeLoading()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
